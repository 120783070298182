import { extend } from 'underscore';
import { Events, ajax } from 'backbone';
import config from '../../config';

export default function() {

    // Mixin Backbone.Events
    extend(this, Events);

    this.data = '';

    this.fetch = function(criteria) {
        ajax({
            url: config.api.url + '/portal/dashboard',
            data: criteria,
            dataType: 'text',
        })
        .then((data) => {
            this.data = data;   // Don't call JSON.parse() here, otherwise .toJSON() will always return the same object by reference
            this.trigger('change');
            this.trigger('sync');
        })
        .catch(() => {
            this.trigger('error');
        });

        this.trigger('request');
    };

    this.toJSON = function() {
        return JSON.parse(this.data);   // Call JSON.parse() here, so we don't return the original result object by reference
    };

}